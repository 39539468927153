
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="-153 -46 652 652" style="enable-background:new -153 -46 652 652;" xml:space="preserve">
<path class="st0" d="M354.5,310.5h-10.7l-1.4-9.3c-2.4-0.6-4.6-1.5-6.7-2.8l-7.6,5.6l-7.6-7.6l5.6-7.6c-1.2-2.1-2.2-4.3-2.8-6.7
	l-9.3-1.4v-10.7l9.3-1.4c0.6-2.4,1.6-4.6,2.8-6.7l-5.6-7.6l7.6-7.6l7.6,5.6c2.1-1.2,4.3-2.2,6.7-2.8l1.4-9.3h10.7l1.4,9.3
	c2.3,0.6,4.6,1.5,6.7,2.8l7.6-5.6l7.6,7.6l-5.6,7.6c1.2,2.1,2.2,4.3,2.8,6.7l9.3,1.4v10.7l-9.3,1.4c-0.6,2.3-1.5,4.6-2.8,6.7
	l5.6,7.6l-7.6,7.6l-7.6-5.6c-2.1,1.2-4.4,2.2-6.7,2.8L354.5,310.5z M349.1,263.7c-6.5,0-11.7,5.3-11.7,11.7
	c0,6.5,5.3,11.7,11.7,11.7c6.5,0,11.7-5.3,11.7-11.7C360.8,269,355.6,263.7,349.1,263.7"/>
<path class="st1" d="M458.9,253.7c-2.8,0-5.2-1-7.1-3c-2-2-3-4.4-3-7.1c0-2.8,1-5.2,3-7.2c2-2,4.4-2.9,7.1-2.9
	c1.8,0,3.5,0.4,5.1,1.3c1.6,0.9,2.8,2.1,3.7,3.7c0.9,1.6,1.3,3.3,1.3,5.1c0,2.8-1,5.2-2.9,7.1C464.1,252.7,461.6,253.7,458.9,253.7
	 M458.9,235.5c-2.3,0-4.2,0.8-5.8,2.4c-1.6,1.6-2.4,3.5-2.4,5.8c0,2.2,0.8,4.2,2.4,5.8c1.6,1.6,3.5,2.4,5.8,2.4
	c2.2,0,4.2-0.8,5.8-2.4c1.6-1.6,2.4-3.6,2.4-5.8c0-2.3-0.8-4.2-2.4-5.8C463,236.3,461.1,235.5,458.9,235.5 M464,249.6h-2.6l-2.9-4.5
	h-1.6v4.5h-2.1v-11.9h4.9c1.1,0,2,0.4,2.7,1c0.8,0.7,1.1,1.6,1.1,2.7c0,1.2-0.4,2.1-1.1,2.7c-0.5,0.4-1,0.7-1.5,0.9L464,249.6z
	 M456.9,243.2h2.8c0.4,0,0.8-0.2,1.2-0.5c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.3c-0.4-0.4-0.8-0.5-1.2-0.5h-2.8V243.2z"/>
<path class="st1" d="M-73.6,303.6l16.5-78.8h17l-6.5,31l0.3,0.1c4.5-4.9,10.4-8.2,17.9-8.2c8.9,0,13.9,5.2,13.9,14
	c0,2.7-0.5,6.7-1.2,10.1l-6.6,31.9h-17l6.4-30.9c0.5-2.1,0.7-4.7,0.7-6.8c0-3.4-1.5-5.7-5.3-5.7c-5.4,0-11.2,6.3-13.4,16.4
	l-5.7,26.9H-73.6z"/>
<path class="st1" d="M17.8,325.5l11.8-56.4c1.4-6.4,2.7-14.9,3.4-20.5h15l-1,8.3h0.2c4.6-5.8,11.2-9.1,17.9-9.1
	c12.4,0,17.5,9.3,17.5,19.7c0,18.8-13,37.3-33,37.3c-4.2,0-8-0.9-9.9-2h-0.4l-4.7,22.8H17.8z M42,290.9c1.8,1.3,3.9,2.2,7,2.2
	c9.5,0,16-14.2,16-24.1c0-4.1-1.6-8.5-6.8-8.5c-5.9,0-11.4,6.4-13.4,16L42,290.9z"/>
<path class="st1" d="M173.3,234.3l-3.1,14.6h12.4l-2.5,11.5h-12.4l-4.4,20.5c-0.5,2.1-0.9,4.6-0.9,6.3c0,2.9,1.5,4.6,5,4.6
	c1.4,0,3.1,0,4.5-0.2l-2,12c-2.4,0.8-6.5,1.1-10.1,1.1c-10.1,0-15-4.8-15-11.9c0-2.8,0.4-5.8,1.2-9.6l4.8-22.7h-7.5L146,249h7.5
	l2.1-10.1L173.3,234.3z"/>
<path class="st1" d="M216.3,303.6c0.1-3.1,0.5-6.6,0.7-10.3h-0.4c-5.3,8.5-12.4,11.4-18.7,11.4c-10.2,0-16.7-7.4-16.7-18.2
	c0-20.7,9.8-38,40.5-38c6.8,0,13.9,1.2,18.6,2.6l-6.2,28.4c-1.5,6.9-2.6,17.5-2.5,24.1H216.3z M221.4,260.7
	c-1.4-0.3-2.8-0.4-4.3-0.4c-12.6,0-18.4,12.1-18.4,23.1c0,5.3,1.8,8.8,6.4,8.8c5,0,10.8-5.7,13.4-18.1L221.4,260.7z"/>
<path class="st1" d="M276,234.3L273,249h12.4l-2.4,11.5h-12.4l-4.4,20.5c-0.5,2.1-0.9,4.6-0.9,6.3c0,2.9,1.5,4.6,5,4.6
	c1.4,0,3.1,0,4.5-0.2l-2,12c-2.5,0.8-6.5,1.1-10.1,1.1c-10.1,0-15-4.8-15-11.9c0-2.8,0.4-5.8,1.2-9.6l4.8-22.7h-7.5l2.5-11.5h7.5
	l2.1-10.1L276,234.3z"/>
<path class="st1" d="M384.2,303.6l7.7-36.9c1.5-6.7,2.3-12.7,3.2-17.8H410l-1,8.4h0.2c5.2-6.5,12.1-9.6,19.6-9.6
	c9.5,0,14.4,5.5,14.4,14.1c0,2.7-0.5,6.6-1.1,9.6l-6.8,32.2h-17l6.5-31.4c0.4-1.9,0.6-4,0.6-6c0-3.5-1.4-5.9-5.3-5.9
	c-5.2,0-11.3,6-13.6,17.7l-5.3,25.7H384.2z"/>
<path class="st1" d="M11,241.9h0.2c5.2,0,9.7-3.2,9.8-8.8c0-4.4-3.3-7.6-8.4-7.6c-5.4,0-9.6,3.9-9.6,8.9
	C3.1,238.9,6.2,241.9,11,241.9"/>
<path class="st1" d="M17,248.6H0.5l-7.1,33.8c-0.9,3.8-1.2,6.8-1.2,9.5c0,7,4.9,11.7,14.8,11.7c3.5,0,7.5-0.3,10-1.1l1.9-11.8
	c-1.5,0.2-3.2,0.2-4.5,0.2c-3.5,0-5-1.7-5-4.5c0-1.7,0.4-4.1,0.9-6.2L17,248.6z"/>
<path class="st1" d="M305.2,241.9h0.2c5.2,0,9.7-3.2,9.9-8.8c0-4.4-3.3-7.6-8.4-7.6c-5.3,0-9.6,3.9-9.6,8.9
	C297.3,238.9,300.5,241.9,305.2,241.9"/>
<path class="st1" d="M311.3,248.6h-16.5l-7.1,33.8c-0.9,3.8-1.2,6.8-1.2,9.5c0,7,4.9,11.7,14.8,11.7c3.5,0,7.5-0.3,10-1.1l1.9-11.8
	c-1.5,0.2-3.2,0.2-4.5,0.2c-3.5,0-5-1.7-5-4.5c0-1.7,0.4-4.1,0.8-6.2L311.3,248.6z"/>
<path class="st1" d="M-93.8,260.8c-6.9-4.1-10.9-7.6-10.9-11.7c0-4.6,4.4-8.4,12.2-8.4c5.6-0.1,11.4,2.3,15.5,4.6l3.2-13.4l0.1-0.3
	c-3.1-1.6-8.7-4.3-17.7-4.3c-19.5,0-30.9,8.9-30.9,24.3c0,8.8,6.9,15.5,14.9,20.2c6.9,3.9,10.3,5.9,10.3,10.4c0,5.9-5.9,9.2-12.8,9
	c-6.6,0-13.5-3.7-17.8-6l-3.4,14.1c3.7,2.2,10.6,5.3,19.9,5.4c18.6,0,31.8-7,31.8-25.1C-79.3,271.3-84.7,265.8-93.8,260.8"/>
<path class="st1" d="M120.7,260.8c-6.9-4.1-10.9-7.6-10.9-11.7c0-4.6,4.4-8.4,12.2-8.4c5.6-0.1,11.4,2.3,15.5,4.6l3.2-13.4l0.1-0.3
	c-3.1-1.6-8.8-4.3-17.7-4.3c-19.5,0-30.9,8.9-30.9,24.3c0,8.8,6.9,15.5,14.9,20.2c6.9,3.9,10.3,5.9,10.3,10.4c0,5.9-5.9,9.2-12.8,9
	c-6.6,0-13.5-3.7-17.8-6l-3.4,14.1c3.7,2.2,10.6,5.3,19.9,5.4c18.6,0,31.9-7,31.9-25.1C135.3,271.3,129.8,265.8,120.7,260.8"/>
</svg>

  </template>

  <script>
  export default {
    name: 'Shipstation',
    inheritAttrs: true,
  }
  </script>
